
import { defineComponent } from 'vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import NotificationStoreEvents from '@/constants/NotificationStoreEvents';
import IncomingForecast from '@/domain/IncomingForecast';
import ImportErrorTable from '@/modules/master-data/views/import-errors/components/ImportErrorTable.vue';
import IncomingForecastService from '@/services/IncomingForecastService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { ColumnDefinition } from '@/types';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';

export default defineComponent({
    name: 'incoming-demand-forecast-import-errors-screen',
    components: {
        ImportErrorTable,
        SimpleDatePopover,
    },
    setup() {
        const incomingForecastService = new IncomingForecastService();

        const updateDataRoute = MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_IDF;

        const tableColumns: ColumnDefinition<IncomingForecast>[] = [
            {
                key: 'fromLocation',
                label: getTitleCaseTranslation('core.domain.fromLocation'),
            },
            {
                key: 'toLocation',
                label: getTitleCaseTranslation('core.domain.toLocation'),
            },
            {
                key: 'partnerReferenceNumber',
                label: getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
            },
            {
                key: 'plannedDeliveryDate',
                label: getTitleCaseTranslation('core.domain.plannedDeliveryDate'),
            },
            {
                key: 'createdOn',
                label: getTitleCaseTranslation('core.domain.createdOn'),
            },
            {
                key: ['partItemNumber'],
                label: getTranslation('core.domain.partItemNumber'),
            },
            {
                key: ['quantity'],
                label: getTranslation('core.domain.partItemQuantity'),
            },
        ];

        return {
            getTitleCaseTranslation,
            incomingForecastService,
            NotificationStoreEvents,
            tableColumns,
            updateDataRoute,
        };
    },
});
