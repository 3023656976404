import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_import_error_table = _resolveComponent("import-error-table")!

  return (_openBlock(), _createBlock(_component_import_error_table, {
    "import-error-service": _ctx.incomingForecastService,
    "push-notification-subscription-key": _ctx.NotificationStoreEvents.MASTER_DATA.IMPORT_ERRORS.IDF,
    "table-columns": _ctx.tableColumns,
    "table-key": "incomingForecastList",
    "table-title": _ctx.getTitleCaseTranslation('core.domain.importErrorsDemandForecast'),
    "update-data-route": _ctx.updateDataRoute
  }, {
    "cell(plannedDeliveryDate)": _withCtx((row) => [
      _createVNode(_component_simple_date_popover, {
        date: row.item.plannedDeliveryDate
      }, null, 8, ["date"])
    ]),
    "cell(createdOn)": _withCtx((row) => [
      _createVNode(_component_simple_date_popover, {
        date: row.item.createdOn
      }, null, 8, ["date"])
    ]),
    "cell(partItemNumber)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.failedIncomingForecastLines, (failedLine) => {
        return (_openBlock(), _createElementBlock("p", {
          key: failedLine.id
        }, _toDisplayString(failedLine.itemNumber || failedLine.productionPartNumber), 1))
      }), 128))
    ]),
    "cell(quantity)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.failedIncomingForecastLines, (failedLine) => {
        return (_openBlock(), _createElementBlock("p", {
          key: failedLine.id
        }, _toDisplayString(failedLine.quantity), 1))
      }), 128))
    ]),
    _: 1
  }, 8, ["import-error-service", "push-notification-subscription-key", "table-columns", "table-title", "update-data-route"]))
}